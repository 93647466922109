import { config } from './../config/config';

const API_ENDPOINTS = {
  CUSTOMER_DETAILS: `${config.API_BASE_URL}/getCustomerDetails`,
  CUSTOMER_DELIVERY_ADDRESSES: `${config.API_BASE_URL}/getCustomerDeliveryAddresses`,
  PRODUCT_TYPES: `${config.API_BASE_URL}/getProductTypes`,
  PRODUCT_DETAILS: `${config.API_BASE_URL}/getProductDetails`,
  CHECK_STOCK: `${config.API_BASE_URL}/getStock`,
  ORDER_HISTORY: `${config.API_BASE_URL}/getOrderHistory`,
  SALES_ORDER: `${config.API_BASE_URL}/postSalesOrder`,
  GET_PRICE: `${config.API_BASE_URL}/getPrice`,
  STATEMENT: `${config.API_BASE_URL}/getStatement`,
  EMAIL_SUBMIT_MESSAGE: `${config.API_EMAIL_BASE_URL}/submitMessage`,
  EMAIL_FORGOT_PASSWORD: `${config.API_EMAIL_BASE_URL}/forgotPassword`,
  EMAIL_NEW_PASSWORD: `${config.API_EMAIL_BASE_URL}/newPassword`,
  EMAIL_NOTIFY_STOCK: `${config.API_EMAIL_BASE_URL}/notifyStock`,
  EMAIL_DELETE_ACCOUNT:  `${config.API_EMAIL_BASE_URL}/deleteAccount`,
  EMAIL_UPDATE_DETAILS:  `${config.API_EMAIL_BASE_URL}/updateDetails`,
  ACCOUNT_SUMMARY:  `${config.API_BASE_URL}/getStatement`,
  LOGIN:  `${config.API_BASE_URL}/login`,
  COLOUR_GROUPS: `${config.API_BASE_URL}/getColourGroups`,
  GET_PRICELIST: `${config.API_BASE_URL}/getPricelist`,
  GET_BANKS: `${config.API_BASE_URL}/getBanks`,
};


const APP_ROUTES = {
  ABOUT:  '/about',
  SEARCH: '/search',
  PRODUCTS: '/products',
  PRODUCT: '/product',
  CART: '/cart',
  ORDER_CONFIRMATION: '/order-confirmation',
  ACCOUNT: '/account',
  ORDERS: '/account/orders',
  PAYMENTS: '/account/payments',
  STATEMENTS: '/account/statements',
  UPDATE_ACCOUNT_INFO: '/account/update-account-info',
  BILLING_DETAILS: '/account/billing-details',
  CONTACT: '/contact',
  TERMS: '/terms',
  COOKIES: '/cookies',
  PRIVACY: '/privacy',
  LOGIN: '/login',
  PRODUCT_CUT: '/product-cut',
  DASHBOARD: '/',
  DELETE_ACCOUNT: '/deleteaccount',
  ACCOUNT_SUMMARY: '/accountsummary',
  CLEARANCE: '/clearance',
  PRICELIST: '/pricelist',
  PASSWORD_CHANGE: '/passwordchange',
  AVAILABLE_BANKS: '/banks'
};

const IMAGE_BASE_URL = config.IMAGE_BASE_URL;

const CONSTANTS = {
  API_ENDPOINTS,
  APP_ROUTES,
  IMAGE_BASE_URL,
};

export default CONSTANTS;
